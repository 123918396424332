<template>
  <form-time />
</template>

<script>
import formTime from '../form/medication/Form-medication-time.vue'

export default ({
  components: {
    formTime,
  },
})
</script>
